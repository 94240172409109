import React, { useState, useRef, useCallback } from 'react';
import { Polygon } from '@react-google-maps/api';

// eslint-disable-next-line react/prop-types
const MapPolygon = ({ polygonPath, isDraggable, isEditable, polygonOptions, onClick, setPath }) => {
  const options = {
    ...polygonOptions,
    strokeWeight: 2.0
  };
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));
      setPath(nextPath);
    }
  }, [setPath]);

  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const newPath = polygon.getPath();
      listenersRef.current.push(
        newPath.addListener('set_at', onEdit),
        newPath.addListener('insert_at', onEdit),
        newPath.addListener('remove_at', onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  return (
    <Polygon
      onClick={onClick}
      options={options}
       // eslint-disable-next-line react/prop-types
      path={polygonPath}
      draggable={isDraggable}
      editable={isEditable}
            // Event used when manipulating and adding points
      onMouseUp={isEditable ? onEdit : () => {}}
            // Event used when dragging the whole Polygon
      onDragEnd={isDraggable ? onEdit : () => {}}
      onLoad={onLoad}
      onUnmount={onUnmount}
    />
  );
};

export default MapPolygon;
