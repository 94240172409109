import React from 'react';
import { Formik } from 'formik';
import { Box, Card, Grid, TextField } from '@material-ui/core';

// eslint-disable-next-line react/prop-types
const LiveMapDriverForm = ({ initialValues }) => (
  <Formik
    initialValues={initialValues[0]}
    enableReinitialize
    onSubmit={() => {
      console.log('submit');
    }}
  >
    {({ values, handleBlur }) => (
      <Card>
        <Box sx={{ p: 3 }}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Name"
                name="name"
                onBlur={handleBlur}
                value={values.name}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Location"
                name="location"
                onBlur={handleBlur}
                value={values.location.name}
                variant="outlined"
                disabled
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
    )}
  </Formik>
);

export default LiveMapDriverForm;
