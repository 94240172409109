import React, { useState } from 'react';
import MapMain from '../../components/map/MapMain';
import SelectableMarker from '../../components/map/SelectableMarker';
import { Grid } from '@material-ui/core';
import LiveMapDriverForm from '../../components/liveMap/LiveMapDriverForm';

const fakeDriverData = [
  {
    id: '1',
    name: 'Ahmad Sidani',
    location: {
      name: 'Raucheh',
      lat: 33.324272,
      lng: 35.494160
    }
  },
  {
    id: '2',
    name: 'Temp User',
    location: {
      name: 'Verdun',
      lat: 33.8871,
      lng: 35.4741
    }
  },
  {
    id: '3',
    name: 'Another User',
    location: {
      name: 'Hamra',
      lat: 33.84675,
      lng: 35.90203
    }
  }
];

const LiveMap = () => {
  const [mapCenter, setMapCenter] = useState({ lat: 33.8547, lng: 35.8623 });
  const [selectedMarkerId, setSelectedMarkerId] = useState();
  return (
    <Grid
      container
      spacing={10}
      style={{ padding: 30 }}
    >
      <Grid
        item
        md={6}
      >
        <MapMain center={mapCenter}>
          {fakeDriverData?.map(({ location: { lat, lng }, id, name }) => (
            <SelectableMarker
              id={id}
              lat={lat}
              lng={lng}
              title={name}
              selectedMarkerId={selectedMarkerId}
              setSelectedMarkerId={setSelectedMarkerId}
            />
          ))}
        </MapMain>
      </Grid>
      <Grid
        item
        md={6}
      >

        {selectedMarkerId ? (
          <LiveMapDriverForm
            initialValues={fakeDriverData.filter(({ id }) => id === selectedMarkerId)}
          />
        )
          : <h2 style={{ textTransform: 'capitalize' }}>Select marker to show Driver Data</h2>}
      </Grid>
    </Grid>
  );
};

export default LiveMap;
