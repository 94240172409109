import moment from 'moment';
import { useQuery } from '@apollo/client';
import { GET_MONTHLY_ACTIVE_DAYS } from '../queries/activeDays';

export const formatTimeStamp = (data, format, defaultOption) => (data ? moment.unix(data / 1000).format(format) : defaultOption || '');
export const formatCreatedAtTimeStamp = (data) => (
  <p>
    {formatTimeStamp(data, 'DD/MM/YYYY', '- : - : - : -')}
    {' '}
    {' - '}
    <span style={{ fontWeight: 'bold' }}>{formatTimeStamp(data, 'hh:mm a')}</span>
  </p>
);
export const formatCustomDate = (data) => (
  <p>
    {formatTimeStamp(data, 'DD/MM/YYYY', '- : - : - : -')}
  </p>
);
export const momentGetDatesFormat = (data) => data?.map(((x) => {
  if (x?._id?.month) {
    return `${moment().month(x?._id?.month - 1).format('MMM')} - ${x?._id?.year}`;
  } if (x?._id?.dayOfWeek) {
    if (x?._id?.hour) {
      return `${moment().weekday(x?._id?.dayOfWeek - 1).format('ddd')} ${x?._id?.hour}:00 - ${x?._id?.year}`;
    }
    return `${moment().weekday(x?._id?.dayOfWeek - 1).format('ddd')} - ${x?._id?.year}`;
  } if (x?._id?.week) {
    return `${x?._id?.week} - ${x?._id?.year}`;
  } if (x?._id?.dayOfMonth) {
    return `${x?._id?.dayOfMonth} - ${x?._id?.year}`;
  }
  if (x?._id?.dayOfYear) {
    return `${moment().dayOfYear(x?._id?.dayOfYear).year(x?._id?.year).format('DD/MM/YYYY')} - ${x?._id?.year}`;
  }
  return x?._id?.year;
}));

export const getStartDateEndDate = (format, startDate, endDate) => {
  switch (format) {
    case 'yesterday-today':
      return ({
        startDate: moment().subtract(1, 'days').format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY')
      });
    case 'lastWeek-thisWeek':
      return ({
        startDate: moment().subtract(1, 'weeks').startOf('week').format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY')
      });
    case 'lastMonth-thisMonth':
      return ({
        startDate: moment().subtract(1, 'month').startOf('month').format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY')
      });
    case 'lastYear-thisYear':
      return ({
        startDate: moment().subtract(1, 'years').startOf('year').format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY')
      });
    default: return ({});
  }
};
