import React from 'react';
import { Box, InputLabel, MenuItem, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { selectDateOptions } from './preDefinedDates';
import PropTypes from 'prop-types';

const DatePickerDropDown = ({ selectedOption, setSelectedOption }) => (
  <Box sx={{ mr: 2 }}>
    <FormControl
      fullWidth
      style={{ minWidth: 180 }}
    >
      <InputLabel id="demo-simple-select-label">Choose your date</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedOption}
        onChange={(e) => {
          setSelectedOption(e.target.value);
        }}
      >
        {selectDateOptions.map(({ value, label }) => (
          <MenuItem
            key={value}
            value={value}
          >
            {label}
          </MenuItem>
        ))}
        <MenuItem
          disabled
          value="Custom"
        >
          Custom...
        </MenuItem>
      </Select>
    </FormControl>
  </Box>
);

DatePickerDropDown.propTypes = {
  selectedOption: PropTypes.string.isRequired,
  setSelectedOption: PropTypes.func.isRequired
};
export default DatePickerDropDown;
