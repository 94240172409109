import moment from 'moment';

export const selectDateOptions = [
  { value: 'TODAY', label: 'Today' },
  { value: 'YESTERDAY', label: 'Yesterday' },
  { value: 'THIS_WEEK', label: 'This Week' },
  { value: 'LAST_WEEK', label: 'Last Week' },
  { value: 'LAST_7_DAYS', label: 'Last 7 Days' },
  { value: 'LAST_28_DAYS', label: 'Last 28 Days' },
  { value: 'LAST_30_DAYS', label: 'Last 30 Days' },
  { value: 'LAST_90_DAYS', label: 'Last 90 Days' },
  { value: 'LAST_CALENDAR_YEAR', label: 'Last Calendar Year' },
  { value: 'THIS_YEAR', label: 'This year (Jan - Today)' },
  { value: 'THIS_MONTH', label: 'This Month' },
  { value: 'LAST_MONTH', label: 'Last Month' },
  { value: 'LAST_12_MONTHS', label: 'Last 12 Months' }
];

export const getDateRange = (key) => {
  switch (key) {
    case 'TODAY':
      return {
        start: moment(),
        end: moment()
      };
    case 'YESTERDAY':
      return {
        start: moment().subtract(1, 'days'),
        end: moment().subtract(1, 'days')
      };
    case 'THIS_WEEK':
      return {
        start: moment().startOf('week'),
        end: moment().endOf('week'),
      };
    case 'LAST_WEEK':
      return {
        start: moment().subtract(1, 'weeks').startOf('week'),
        end: moment().subtract(1, 'weeks').endOf('week'),
      };
    case 'LAST_7_DAYS':
      return {
        start: moment().subtract(7, 'days'),
        end: moment()
      };
    case 'LAST_28_DAYS':
      return {
        start: moment().subtract(28, 'days'),
        end: moment()
      };
    case 'LAST_30_DAYS':
      return {
        start: moment().subtract(30, 'days'),
        end: moment()
      };
    case 'LAST_90_DAYS':
      return {
        start: moment().subtract(90, 'days'),
        end: moment()
      };
    case 'LAST_12_MONTHS':
      return {
        start: moment().subtract(12, 'months'),
        end: moment()
      };
    case 'LAST_CALENDAR_YEAR':
      return {
        start: moment().subtract(1, 'years').startOf('year'),
        end: moment().subtract(1, 'years').endOf('year')
      };
    case 'THIS_YEAR':
      return {
        start: moment().startOf('year'),
        end: moment()
      };
    case 'THIS_MONTH':
      return {
        start: moment().startOf('month'),
        end: moment()
      };
    case 'LAST_MONTH':
      return {
        start: moment().subtract(1, 'month').startOf('month'),
        end: moment().subtract(1, 'month').endOf('month')
      };
    default:
      return {
        start: moment().startOf('day'),
        end: moment().startOf('day'),
      };
  }
};
