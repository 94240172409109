import { gql } from '@apollo/client';

const GET_MONTHLY_ACTIVE_DAYS = gql`
    query getMonthlyActiveDays{
        monthlyActiveDaysStats
    }
`;
export {
  GET_MONTHLY_ACTIVE_DAYS
};
