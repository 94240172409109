import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ZONE_BY_ID } from '../../queries/zones';
import { Button, Grid } from '@material-ui/core';
import MapMain from '../../components/map/MapMain';
import MapPolygon from '../../components/map/MapPolygon';
import { flatUiColours } from '../../utils/flatUiColours';
import CreateZoneForm from '../../components/driverZones/CreateZoneForm';
import ZoneDetailsCard from '../../components/zones/ZoneDetailsCard';

const ZoneDetails = () => {
  const { zoneId } = useParams();
  const { data, error, loading } = useQuery(GET_ZONE_BY_ID, {
    variables: {
      geofenceId: zoneId
    }
  });
  const zones = data?.geofence;
  const mapCenter = { lat: zones?.coordinates[0].latitude,
    lng: zones?.coordinates[0].longitude
  };
  if (loading) {
    return <h1>Loading...</h1>;
  }
  let path = zones?.coordinates;
  path = path.map((s) => ({ lat: s.latitude, lng: s.longitude }));
  return (
    <Grid
      container
      style={{ padding: 20 }}
      spacing={10}
    >
      <Grid
        item
        md={6}
      >

        <MapMain
          center={mapCenter}
          zoom={13}
        >
          <MapPolygon
            polygonOptions={{ fillColor: 'blue' }}
            polygonPath={path}
          />
        </MapMain>

      </Grid>
      <Grid
        item
        md={6}
      >
        <ZoneDetailsCard
          category={zones?.category}
          name={zones?.name}
        />
      </Grid>
    </Grid>
  );
};

export default ZoneDetails;
