import React, { useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';
import MapMain from '../../components/map/MapMain';
import { Marker } from '@react-google-maps/api';
import DropOffForm from '../../components/dropOff/DropOffForm';

const DropOffCreate = () => {
  const [mapCenter, setMapCenter] = useState({ lat: 33.8547, lng: 35.8623 });
  const [markerPosition, setMarkerPosition] = useState();
  const handleAddMarkerEvent = useCallback((e) => {
    setMarkerPosition(e.latLng);
    setMapCenter({});
  }, [setMarkerPosition]);
  return (
    <Grid
      container
      style={{ padding: 20 }}
      spacing={10}
    >
      <Grid
        item
        md={6}
      >
        <MapMain
          onClick={handleAddMarkerEvent}
          center={mapCenter}
        >
          {Boolean(markerPosition) && <Marker position={markerPosition} />}
        </MapMain>
      </Grid>
      <Grid
        item
        md={6}
      >
        {Boolean(markerPosition) && <DropOffForm />}
      </Grid>
    </Grid>
  );
};

export default DropOffCreate;
