import React, { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Button, Container, Divider, Grid, Link, Tab, Tabs, Typography } from '@material-ui/core';
import useSettings from '../../hooks/useSettings';
import ChevronRightIcon from '../../icons/ChevronRight';
import gtm from '../../lib/gtm';
import { useQuery } from '@apollo/client';
import { GET_CANCELLED_ORDERS } from '../../queries/orders';
import CancelledOrdersListTable from '../../components/orders/CancelledOrdersListTable';
import DatePickerComponent from '../../components/formFields/DatePicker/DatePickerComponent';
import DatePickerDropDown from '../../components/dashboardAnalytics/DatePickerDropDown';
import { getDateRange } from '../../components/dashboardAnalytics/preDefinedDates';

const CancelledOrdersList = () => {
  const [currentTab, setCurrentTab] = useState('list');
  const { settings } = useSettings();
  const { data: CancelledOrders, error, loading } = useQuery(GET_CANCELLED_ORDERS);
  const orders = CancelledOrders?.getCanceledOrders;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedOption, setSelectedOption] = useState('TODAY');
  useEffect(() => {
    if (selectedOption !== 'Custom') {
      setStartDate(getDateRange(selectedOption).start);
      setEndDate(getDateRange(selectedOption).end);
    }
  }, [selectedOption]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);
  if (loading) {
    return (<h1>Loading Cancelled Orders...</h1>);
  }

  return (
    <>
      <Helmet>
        <title>Dashboard: Cancelled Orders</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Cancelled Orders
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Canceled Orders
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 3 }} />
          {/* <Box */}
          {/*  sx={{ */}
          {/*    display: 'flex', */}
          {/*    mt: 2 */}
          {/*  }} */}
          {/* > */}
          {/*  <DatePickerComponent */}
          {/*    selectedDate={startDate} */}
          {/*    label="Start Date" */}
          {/*    setSelectedDate={setStartDate} */}
          {/*    changeLabel={setSelectedOption} */}
          {/*  /> */}
          {/*  <DatePickerComponent */}
          {/*    selectedDate={endDate} */}
          {/*    label="End Date" */}
          {/*    setSelectedDate={setEndDate} */}
          {/*    changeLabel={setSelectedOption} */}
          {/*  /> */}
          {/*  <DatePickerDropDown */}
          {/*    setSelectedOption={setSelectedOption} */}
          {/*    selectedOption={selectedOption} */}
          {/*  /> */}
          {/* </Box> */}
          <Box sx={{ mt: 3 }}>
            <CancelledOrdersListTable
              orders={orders}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CancelledOrdersList;
