import React, { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import DriverCreate from './pages/dashboard/DriverCreate';
import authRoutes from './routes/auth.routes';
import systemRoutes from './routes/system.routes';
import DropOffCreate from './pages/dropoff/DropOffCreate';
import MapPolygonView from './pages/mapPolygon/MapPolygonView';
import LiveMap from './pages/liveMap/LiveMap';
import CancelledOrdersList from './pages/dashboard/CancelledOrdersList';
import OrdersMap from './pages/dashboard/OrdersMap';
import ZoneDetails from './pages/dashboard/ZoneDetails';
import { Helmet } from 'react-helmet-async';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Dashboard pages

const CustomerDetails = Loadable(lazy(() => import('./pages/dashboard/CustomerDetails')));
const CustomerEdit = Loadable(lazy(() => import('./pages/dashboard/CustomerEdit')));
const DriverEdit = Loadable(lazy(() => import('./pages/dashboard/DriverEdit')));
const CustomerList = Loadable(lazy(() => import('./pages/dashboard/CustomerList')));
const CustomersMap = Loadable(lazy(() => import('./pages/customers/CustomersMap')));
const DriverList = Loadable(lazy(() => import('./pages/dashboard/DriverList')));
const PaymentList = Loadable(lazy(() => import('./pages/payment/PaymentList')));
const DropOff = Loadable(lazy(() => import('./pages/dropoff/DropOffListMap')));
const DriverDetails = Loadable(lazy(() => import('./pages/dashboard/DriverDetails')));
const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')));
const InvoiceDetails = Loadable(lazy(() => import('./pages/dashboard/InvoiceDetails')));
const InvoiceList = Loadable(lazy(() => import('./pages/dashboard/InvoiceList')));
const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));
const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));
const OrderDetails = Loadable(lazy(() => import('./pages/dashboard/OrderDetails')));
const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
const TopOrderList = Loadable(lazy(() => import('./pages/order/TopOrderList')));
const ProductCreate = Loadable(lazy(() => import('./pages/dashboard/ProductCreate')));
const ProductList = Loadable(lazy(() => import('./pages/dashboard/ProductList')));
const NewUsers = Loadable(lazy(() => import('./pages/analytics/NewUsers')));
const ActiveDaysMonthlyAnalytics = Loadable(lazy(() => import('./pages/analytics/ActiveDaysMonthlyAnalytics')));
const NumberOfOrdersByZoneAnalytics = Loadable(lazy(() => import('./pages/analytics/NumberOfOrdersByZoneAnalytics')));
const OrdersAnalytics = Loadable(lazy(() => import('./pages/analytics/OrdersAnalytics')));
const UserDriverFrequency = Loadable(lazy(() => import('./pages/analytics/UserDriverFrequency')));
const UsersPercentageChange = Loadable(lazy(() => import('./pages/analytics/UsersPercentChange')));
const OrdersPercentChange = Loadable(lazy(() => import('./pages/analytics/OrdersPercentChange')));
const PartnersList = Loadable(lazy(() => import('./pages/partners/PartnersList')));
const PartnersCreate = Loadable(lazy(() => import('./pages/partners/PartnersCreate')));
const PartnersEdit = Loadable(lazy(() => import('./pages/partners/PartnersEdit')));
const PartnerDetails = Loadable(lazy(() => import('./pages/partners/PartnerDetails')));

// Projects pages

const ProjectBrowse = Loadable(lazy(() => import('./pages/dashboard/ProjectBrowse')));
const ProjectCreate = Loadable(lazy(() => import('./pages/dashboard/ProjectCreate')));
const ProjectDetails = Loadable(lazy(() => import('./pages/dashboard/ProjectDetails')));
const ML = Loadable(lazy(() => import('./pages/ecoScanner/EcoScannersList')));
const MLDetails = Loadable(lazy(() => import('./pages/ecoScanner/EcoScannerDetails')));
const CouponsList = Loadable(lazy(() => import('./pages/coupon/CouponsList')));
const CouponDetails = Loadable(lazy(() => import('./pages/coupon/CouponDetails')));
const CouponEdit = Loadable(lazy(() => import('./pages/coupon/CouponEdit')));
const CouponCreate = Loadable(lazy(() => import('./pages/coupon/CouponCreate')));
const CrowdFundList = Loadable(lazy(() => import('./pages/crowdFund/CrowdFundList')));
const CrowdFundCreate = Loadable(lazy(() => import('./pages/crowdFund/CrowdFundCreate')));
const CrowdFundDetails = Loadable(lazy(() => import('./pages/crowdFund/CrowdFundDetails')));
const CrowdFundEdit = Loadable(lazy(() => import('./pages/crowdFund/CrowdFundEdit')));
const StoragesRequestsList = Loadable(lazy(() => import('./pages/spaces/SpaceRequestsList')));
const StorageRequestDetails = Loadable(lazy(() => import('./pages/spaces/SpaceRequestDetails')));
const StorageRequestCreate = Loadable(lazy(() => import('./pages/spaces/SpaceRequestCreate')));
const StorageRequestEdit = Loadable(lazy(() => import('./pages/spaces/SpaceRequestEdit')));
const StaticsList = Loadable(lazy(() => import('./pages/statics/StaticsList')));
const StaticDetails = Loadable(lazy(() => import('./pages/statics/StaticDetails')));
const StaticEdit = Loadable(lazy(() => import('./pages/statics/StaticEdit')));
const StaticCreate = Loadable(lazy(() => import('./pages/statics/StaticCreate')));
const RecyclableItemsList = Loadable(lazy(() => import('./pages/recyclableItems/RecyclableItemsList')));
const RecyclableItemCreate = Loadable(lazy(() => import('./pages/recyclableItems/RecyclableItemCreate')));
const RecyclableItemDetails = Loadable(lazy(() => import('./pages/recyclableItems/RecyclableItemDetails')));
const OpenHoursSettingsEdit = Loadable(lazy(() => import('./pages/openHoursSettings/OpenHoursSettingsEdit')));
const AppForceUpdateList = Loadable(lazy(() => import('./pages/appForceUpdate/AppForceUpdateList')));
const AppForceUpdateDetails = Loadable(lazy(() => import('./pages/appForceUpdate/AppForceUpdateDetails')));
const AppForceUpdateCreate = Loadable(lazy(() => import('./pages/appForceUpdate/AppForceUpdateCreate')));
const AnalyticsImagesList = Loadable(lazy(() => import('./pages/analyticsImages/AnalyticsImagesList')));
const AnalyticsImagesEdit = Loadable(lazy(() => import('./pages/analyticsImages/AnalyticsImagesEdit')));

// Social pages

const SocialFeed = Loadable(lazy(() => import('./pages/dashboard/SocialFeed')));
const SocialProfile = Loadable(lazy(() => import('./pages/dashboard/SocialProfile')));

const dashboardRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    ...systemRoutes.children,
    {
      path: '/',
      element: (
        <>
          <Helmet>
            <title>Live Love Recycle</title>
          </Helmet>
        </>
      )
    },
    {
      path: 'settings',
      children: [
        {
          path: '/open-hours',
          element: <OpenHoursSettingsEdit />
        },
        {
          path: 'analytics-images',
          children: [
            {
              path: '/',
              element: <AnalyticsImagesList />
            },
            {
              path: ':analyticsImageId/edit',
              element: <AnalyticsImagesEdit />
            }
          ]
        },
        {
          path: '/app-force-update',
          children: [
            {
              path: '/',
              element: <AppForceUpdateList />
            },
            {
              path: ':appForceUpdateId',
              element: <AppForceUpdateDetails />
            },
            {
              path: '/create',
              element: <AppForceUpdateCreate />
            }
          ]
        }
      ]
    },
    {
      path: 'recyclable-items',
      children: [
        {
          path: '/',
          element: <RecyclableItemsList />
        },
        {
          path: ':recyclableItemId',
          element: <RecyclableItemDetails />
        },
        {
          path: '/create',
          element: <RecyclableItemCreate />
        }
      ]
    },
    {
      path: 'customers',
      children: [
        {
          path: '/',
          element: <CustomerList />
        },
        {
          path: '/map',
          element: <CustomersMap />
        },
        {
          path: ':customerId',
          element: <CustomerDetails />
        },
        {
          path: ':customerId/edit',
          element: <CustomerEdit />
        }
      ]
    },
    {
      path: 'drivers',
      children: [
        {
          path: '/',
          element: <DriverList />
        },
        {
          path: ':driverId',
          element: <DriverDetails />
        },
        {
          path: ':driverId/edit',
          element: <DriverEdit />
        },
        {
          path: '/createDriver',
          element: <DriverCreate />
        }
      ]
    },
    {
      path: 'payments',
      children: [
        {
          path: '/',
          element: <PaymentList />
        }
      ]
    },
    {
      path: 'analytics',
      children: [
        {
          path: '/new-users',
          element: <NewUsers />
        },
        {
          path: '/active-days',
          element: <ActiveDaysMonthlyAnalytics />
        },
        {
          path: '/number-of-orders-by-zone',
          element: <NumberOfOrdersByZoneAnalytics />
        },
        {
          path: '/orders-stats',
          element: <OrdersAnalytics />
        },
        {
          path: '/orders-frequency',
          element: <UserDriverFrequency />
        },
        {
          path: '/new-users-percent-change',
          element: <UsersPercentageChange />
        },
        {
          path: '/orders-percent-change',
          element: <OrdersPercentChange />
        }
      ]
    },
    {
      path: 'dropOff',
      children: [
        {
          path: '/map',
          element: <DropOff />
        },
        {
          path: '/create',
          element: <DropOffCreate />
        }
      ]
    },
    {
      path: 'liveMap',
      children: [
        {
          path: '/',
          element: <LiveMap />
        }
      ]
    },
    {
      path: 'crowd-funds',
      children: [
        {
          path: '/',
          element: <CrowdFundList />
        },
        {
          path: ':crowdFundId',
          element: <CrowdFundDetails />
        },
        {
          path: ':crowdFundId/edit',
          element: <CrowdFundEdit />
        },
        {
          path: '/create',
          element: <CrowdFundCreate />
        },
      ]
    },
    {
      path: 'spaces',
      children: [
        {
          path: '/',
          element: <StoragesRequestsList />
        },
        {
          path: ':spaceId',
          element: <StorageRequestDetails />
        },
        {
          path: ':spaceId/edit',
          element: <StorageRequestEdit />
        },
        {
          path: '/create',
          element: <StorageRequestCreate />
        }
      ]
    },
    {
      path: 'coupons',
      children: [
        {
          path: '/',
          element: <CouponsList />
        },
        {
          path: ':couponId',
          element: <CouponDetails />
        },
        {
          path: ':couponId/edit',
          element: <CouponEdit />
        },
        {
          path: '/create',
          element: <CouponCreate />
        }
      ]
    },
    {
      path: 'zones',
      children: [
        {
          path: '/',
          element: <MapPolygonView />
        },
        {
          path: ':zoneId',
          element: <ZoneDetails />
        }
      ]
    },
    {
      path: 'invoices',
      children: [
        {
          path: '/',
          element: <InvoiceList />
        },
        {
          path: ':invoiceId',
          element: <InvoiceDetails />
        }
      ]
    },
    {
      path: 'kanban',
      element: <Kanban />
    },
    {
      path: 'mail',
      children: [
        {
          path: '/',
          element: (
            <Navigate
              to="/dashboard/mail/all"
              replace
            />
          )
        },
        {
          path: 'label/:customLabel',
          element: <Mail />
        },
        {
          path: 'label/:customLabel/:emailId',
          element: <Mail />
        },
        {
          path: ':systemLabel',
          element: <Mail />
        },
        {
          path: ':systemLabel/:emailId',
          element: <Mail />
        }
      ]
    },
    {
      path: 'eco-scanners',

      children: [
        {
          path: '/',
          element: <ML />
        },
        {
          path: ':ecoScannerId',
          element: <MLDetails />
        }
      ]
    },
    {
      path: 'statics',

      children: [
        {
          path: '/',
          element: <StaticsList />
        },
        {
          path: ':staticId',
          element: <StaticDetails />
        },
        {
          path: ':staticId/edit',
          element: <StaticEdit />
        },
        {
          path: '/create',
          element: <StaticCreate />
        }
      ]
    },
    {
      path: 'partners',
      children: [
        {
          path: '/',
          element: <PartnersList />
        },
        {
          path: ':partnerId',
          element: <PartnerDetails />
        },
        {
          path: ':partnerId/edit',
          element: <PartnersEdit />
        },
        {
          path: '/create',
          element: <PartnersCreate />
        }
      ]
    },
    {
      path: 'orders',
      children: [
        {
          path: '/',
          element: <OrderList />
        },
        {
          path: '/top-orders',
          element: <TopOrderList />
        },
        {
          path: ':orderId',
          element: <OrderDetails />
        },
        {
          path: '/cancelledOrders',
          element: <CancelledOrdersList />
        },
        {
          path: '/map',
          element: <OrdersMap />
        }
      ]
    },
    {
      path: 'finance',
      element: <Finance />
    },
    {
      path: 'products',
      children: [
        {
          path: '/',
          element: <ProductList />
        },
        {
          path: 'new',
          element: <ProductCreate />
        }
      ]
    },
    {
      path: 'projects',
      children: [
        {
          path: 'browse',
          element: <ProjectBrowse />
        },
        {
          path: 'new',
          element: <ProjectCreate />
        },
        {
          path: ':projectId',
          element: <ProjectDetails />
        }
      ]
    },
    {
      path: 'social',
      children: [
        {
          path: 'feed',
          element: <SocialFeed />
        },
        {
          path: 'profile',
          element: <SocialProfile />
        }
      ]
    }
  ]
};

const routes = [
  authRoutes,
  dashboardRoutes

];

export default routes;
