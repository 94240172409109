import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card, Checkbox,
  FormControl, FormControlLabel, FormGroup,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import HourComponent from '../formFields/HourComponent';
import CheckBoxComponent from '../formFields/CheckBoxComponent';
import SelectComponent from '../formFields/SelectComponent';

const recyclablesCategories = [
  { id: 1, name: 'Plastic' },
  { id: 2, name: 'Carton' },
  { id: 3, name: 'Glass' },
  { id: 4, name: 'Cans' },
  { id: 5, name: 'Electronics' }
];
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  multipleFormControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  }
}));

const defaultValues = {
  name: '',
  address: '',
  type: '',
  categories: [],
  maxBagCapacity: '',
  openHour: '07:30',
  closeHour: '19:30'
};

const dropOffTypes = [
  { id: '1', value: 'BIN' },
  { id: '2', value: 'Warehouse' },
  { id: '3', value: 'External Storage' },
];

// eslint-disable-next-line react/prop-types
const DropOffForm = ({ initialValues = defaultValues }) => {
  const classes = useStyles();
  return (
    <Formik
      onSubmit={(data) => {
        console.log(data);
      }}
      initialValues={initialValues}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => ((

        <Card>
          <Box sx={{ p: 3 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  autoComplete="off"
                  fullWidth
                  label="Name"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.name}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  autoComplete="off"
                  fullWidth
                  label="Address"
                  name="address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.address}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <SelectComponent
                  data={dropOffTypes}
                  handleChange={handleChange}
                  classes={classes}
                  values={values?.type}
                  inputLabel="Type"
                />
              </Grid>
              <Grid item />
              <Grid
                item
                md={6}
                xs={12}
              >
                <CheckBoxComponent
                  classes={classes}
                  data={recyclablesCategories}
                  handleChange={handleChange}
                  formLabel="Assign Categories"
                />
              </Grid>

              <Grid item />
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  autoComplete="off"
                  fullWidth
                  type="number"
                  label="Max Bag Capacity"
                  name="maxBagCapacity"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.maxBagCapacity}
                  variant="outlined"
                />
              </Grid>
              <Grid item />
              <Grid
                item
                md={6}
                xs={12}
              >
                <HourComponent
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values?.openHour}
                  label="Open Hour"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <HourComponent
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values.closeHour}
                  label="Close Hour"
                />
              </Grid>

              <Grid item />

            </Grid>
            <Box sx={{ mt: 2 }}>
              <Button
                onClick={handleSubmit}
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Create drop off
              </Button>
            </Box>
          </Box>
        </Card>
      ))}
    </Formik>
  );
};

export default DropOffForm;
