import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

// eslint-disable-next-line react/prop-types
const SelectComponent = ({ classes, values, data, inputLabel, handleChange }) => (

  // eslint-disable-next-line react/prop-types
  <FormControl className={classes?.formControl}>
    <InputLabel id="demo-simple-select-label">{inputLabel}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      name="type"
           // eslint-disable-next-line react/prop-types
      value={values}
      onChange={handleChange}
    >
      {/* eslint-disable-next-line react/prop-types */}
      {data?.map(({ id, value }) => (
        <MenuItem
          key={id}
          value={id}
        >
          {value}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default SelectComponent;
