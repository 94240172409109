import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ORDER_BY_ID } from '../../queries/orders';
import moment from 'moment';
import { formatCreatedAtTimeStamp } from '../../utils/momentFormats';

const OrderMapShowDataCard = ({ orderId }) => {
  const { data, loading } = useQuery(GET_ORDER_BY_ID, {
    variables: {
      orderId
    }
  });
  const selectedOrder = data?.order;
  const driver = selectedOrder?.driver;
  const customer = selectedOrder?.user;
  const pickedUpOn = formatCreatedAtTimeStamp(selectedOrder?.pickedUpOn);
  const acceptedOn = formatCreatedAtTimeStamp(selectedOrder?.acceptedOn);
  const requestedOn = formatCreatedAtTimeStamp(selectedOrder?.requestedOn);
  if (loading) {
    return <h4>Loading...</h4>;
  }
  return (
    <Card>
      <CardHeader title="Order Details" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Customer
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {customer?.firstName}
                {' '}
                {customer?.lastName}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Driver
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {driver?.firstName}
                {' '}
                {driver?.lastName}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Requested On
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {requestedOn}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Accepted On
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {acceptedOn}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Picked Up On
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {pickedUpOn}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <Link
              color="textPrimary"
              component={RouterLink}
              to={`/orders/${orderId}`}
              variant="subtitle2"
            >
              <Button
                color="primary"
                sx={{ m: 1 }}
                variant="contained"
              >
                View Order Details
              </Button>
            </Link>
          </TableRow>

        </TableBody>
      </Table>
    </Card>
  );
};
OrderMapShowDataCard.propTypes = {
  orderId: PropTypes.string
};
export default OrderMapShowDataCard;
