import React, { useCallback, useState, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import MapMain from '../../components/map/MapMain';
import MapPolygon from '../../components/map/MapPolygon';
import { useQuery } from '@apollo/client';
import { GET_ZONES } from '../../queries/zones';
import { flatUiColours } from '../../utils/flatUiColours';
import CreateZoneForm from '../../components/driverZones/CreateZoneForm';
import ZoneDetailsCard from '../../components/zones/ZoneDetailsCard';

const MapPolygonView = () => {
  const [path, setPath] = useState([
    { lat: 33.8966, lng: 35.4823 },
    { lat: 33.7890, lng: 35.4807 },
    { lat: 33.8338, lng: 35.5441 }
  ]);
  const { data, error, loading } = useQuery(GET_ZONES);
  const [zones, setZones] = useState([]);
  const [center, setCenter] = useState({ lat: 33.8553, lng: 35.5359 });
  const [selectedZone, setSelectedZone] = useState({
    name: '',
    category: '',
    id: ''
  });
  const [createZone, setCreateZone] = useState(false);

  const handleClick = () => {
    setCreateZone(!createZone);
  };
  useEffect(() => {
    setZones(data?.adminGetGeofences);
  }, [data]);
  console.log({ zones });
  const zoneTransducer = useCallback((zone) => zone?.coordinates?.map(({ latitude: lat, longitude: lng }) => ({ lat, lng })), []);
  return (
    <Grid
      container
      style={{ padding: 20 }}
      spacing={10}
    >
      <Grid
        item
        md={6}
      >

        <MapMain
          center={center}
          zoom={12}
        >
          {createZone && (
          <MapPolygon
            setPath={setPath}
            polygonPath={path}
            polygonOptions={{ fillColor: 'green' }}
            isEditable
            isDraggable
          />
          )}
          {zones?.map((zone, index) => (
            <MapPolygon
              key={zone?._id}
              polygonOptions={{ fillColor: flatUiColours[index] }}
               // eslint-disable-next-line no-alert
              onClick={() => setSelectedZone({ name: zone?.name, category: zone?.category, id: zone?._id })}
              polygonPath={zoneTransducer(zone)}
            />
          ))}
        </MapMain>

      </Grid>
      <Grid
        item
        md={6}
      >

        <Grid
          item
        />

        <ZoneDetailsCard
          category={selectedZone.category}
          name={selectedZone.name}
          id={selectedZone.id}
        />
        <Grid
          item
          md={12}
        >

          <Button
            style={{ margin: 10 }}
            variant="contained"
            color="secondary"
            onClick={handleClick}
          >

            {createZone ? 'Remove Zone' : 'Create Zone'}
          </Button>
          {createZone && (
          <Grid
            style={{ margin: 10 }}
            item
            md={12}
          >
            <CreateZoneForm path={path} />

          </Grid>
          )}
        </Grid>
      </Grid>

    </Grid>

  );
};

export default MapPolygonView;
