import GuestGuard from '../components/GuestGuard';
import { lazy } from 'react';
import { Loadable } from './helpers';

// Authentication pages
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));

const authRoutes = {
  path: 'auth',
  children: [
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      )
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      )
    }
  ]
};

export default authRoutes;
