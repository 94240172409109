import React from 'react';
import { Grid, TextField } from '@material-ui/core';

// eslint-disable-next-line react/prop-types
const HourComponent = ({ handleBlur, handleChange, values, label, name, disabled }) => (

  <Grid
    item
    md={6}
    xs={12}
  >
    <TextField
      autoComplete="off"
      id="time"
      label={label}
      type="time"
      onBlur={handleBlur}
      onChange={handleChange}
      disabled={disabled}
      required
         // eslint-disable-next-line react/prop-types
      value={values}
      name={name}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 300, // 5 min
      }}
    />
  </Grid>
);

export default HourComponent;
