import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CustomerAddressDataCard from '../customer/CustomerAddressDataCard';
import { Link as RouterLink } from 'react-router-dom';
import PlusIcon from '../../icons/Plus';

const ZoneDetailsCard = ({ category, name, id }) => (
  <Card>
    <CardHeader title="Zone Details" />
    <Divider />
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              Category
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {category}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              Name
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {name}
            </Typography>
          </TableCell>
        </TableRow>
        {id && (
        <TableRow>
          <Link
            color="textPrimary"
            component={RouterLink}
            to={`/zones/${id}`}
            variant="subtitle2"
          >
            <Button
              color="primary"
              sx={{ m: 1 }}
              variant="contained"
            >
              View Zone
            </Button>
          </Link>
        </TableRow>
        )}

      </TableBody>
    </Table>
  </Card>
);
ZoneDetailsCard.propTypes = {
  name: PropTypes.string,
  category: PropTypes.string,
  id: PropTypes.string
};
export default ZoneDetailsCard;
