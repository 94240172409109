import React, { useState } from 'react';
import {
  Box, Card,
  Divider, IconButton, InputAdornment, Link,
  Table, TableBody,
  TableCell,
  TableHead, TablePagination,
  TableRow, TextField,
} from '@material-ui/core';
import Scrollbar from '../Scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import ArrowRightIcon from '../../icons/ArrowRight';
import PropTypes from 'prop-types';
import { formatCreatedAtTimeStamp } from '../../utils/momentFormats';
import SearchIcon from '../../icons/Search';

const applyFilters = (orders, query) => orders?.filter((order) => {
  let matches = true;

  if (query) {
    const properties = ['firstName', 'lastName'];
    let containsQuery = false;
    const driver = order?.driver;
    const customer = order?.user;
    if (query.indexOf(' ') !== -1) {
      const first = query.split(' ')[0];
      const last = query.split(' ')[1];
      if (driver?.firstName?.toLowerCase()?.includes(first.toLowerCase()) && driver?.lastName?.toLowerCase()?.includes(last.toLowerCase())) {
        containsQuery = true;
      } else if (customer?.firstName?.toLowerCase()?.includes(first.toLowerCase()) && customer?.lastName?.toLowerCase()?.includes(last.toLowerCase())) {
        containsQuery = true;
      }
    }

    properties.forEach((property) => {
      if (driver?.[property]?.toLowerCase().includes(query.toLowerCase())) {
        containsQuery = true;
      } else if (customer?.[property]?.toLowerCase().includes(query.toLowerCase())) {
        containsQuery = true;
      }
    });

    if (!containsQuery) {
      matches = false;
    }
  }

  return matches;
});
const applyPagination = (orders, page, limit) => orders?.slice(page * limit, page * limit + limit);
const CancelledOrdersListTable = ({ orders }) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(100);
  const [query, setQuery] = useState('');
  const filteredOrders = applyFilters(orders, query);
  const paginatedOrders = applyPagination(filteredOrders, page, limit);
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };
  if (orders?.length === 0) {
    return (<h1>No orders Found</h1>);
  }

  return (
    <Card>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500
          }}
        >
          <TextField
            autoComplete="off"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search by Driver or Customer"
            value={query}
            variant="outlined"
          />
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2
        }}
      />
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Customer
                </TableCell>
                <TableCell>
                  Driver
                </TableCell>
                <TableCell>
                  Zones
                </TableCell>
                <TableCell>
                  # bags
                </TableCell>
                <TableCell>
                  Payment
                </TableCell>
                <TableCell>
                  Requested On
                </TableCell>
                <TableCell>
                  Accepted On
                </TableCell>
                <TableCell align="right">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedOrders?.map((order) => {
                const orderDetailsUrl = `/orders/${order?._id}`;
                return (
                  <TableRow
                    hover
                                        // eslint-disable-next-line no-underscore-dangle
                    key={order._id}
                  >
                    <TableCell>
                      <Link
                        to={`/customers/${order?.user?._id}`}
                        varient="subtitle2"
                        component={RouterLink}
                        color="textPrimary"
                      >
                        {order?.user?.firstName}
                        {' '}
                        {order?.user?.lastName}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/customers/${order?.user?._id}`}
                        varient="subtitle2"
                        component={RouterLink}
                        color="textPrimary"
                      >
                        {order?.driver?.firstName}
                        {' '}
                        {order?.driver?.lastName}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {order?.zones?.map(({ _id, name }) => (
                        <Link
                          color="textPrimary"
                          component={RouterLink}
                          to={`/zones/${_id}`}
                          variant="subtitle2"
                          margin={0.5}
                        >
                          {name}
                        </Link>
                      ))}
                    </TableCell>
                    <TableCell>
                      {order?.numberOfBags}
                    </TableCell>
                    <TableCell>
                      {order?.payment?.amount?.toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {formatCreatedAtTimeStamp(order?.requestedOn)}
                    </TableCell>
                    <TableCell>
                      {formatCreatedAtTimeStamp(order?.acceptedOn)}
                    </TableCell>

                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={orderDetailsUrl}
                      >
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={filteredOrders?.length || 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
      />
    </Card>
  );
};
CancelledOrdersListTable.propTypes = {
  orders: PropTypes.array
};
export default CancelledOrdersListTable;
