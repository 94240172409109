import { gql } from '@apollo/client';

export const GET_ZONES = gql`
    query GetZones {
        adminGetGeofences{
            _id
            name
            category
            coordinates{
                latitude
                longitude
            }
        }
    }
`;
export const GET_ZONE_BY_ID = gql`
    query getGeofenceById($geofenceId:ID!){
        geofence(geofenceId:$geofenceId){
            _id
            category
            name
            coordinates{
                latitude
                longitude
            }
        }
    }
`;
