import MainLayout from '../components/MainLayout';
import { lazy } from 'react';
import { Loadable } from './helpers';

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('../pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('../pages/NotFound')));
const ServerError = Loadable(lazy(() => import('../pages/ServerError')));
const systemRoutes = {
  path: '*',
  element: <MainLayout />,
  children: [
    {
      path: '401',
      element: <AuthorizationRequired />
    },
    {
      path: '404',
      element: <NotFound />
    },
    {
      path: '500',
      element: <ServerError />
    },
    {
      path: '*',
      element: <NotFound />
    }
  ]
};

export default systemRoutes;
