import React, { memo, useState } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

// eslint-disable-next-line react/prop-types
const MapMain = ({ children, center, zoom = 9, ...props }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBkPeWJG5VUkNRMqLzT32r2b6G2FlPTRvM'
  });
  const [map, setMap] = useState(null);

  if (loadError) {
    return (<h1>Error loading map</h1>);
  }

  return (
    <div>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '80vh' }}
          center={center}
          zoom={zoom}
          {...props}
        >
          {children}
        </GoogleMap>
      ) : <h1>Loading</h1>}

    </div>
  );
};

export default memo(MapMain);
