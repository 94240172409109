import React from 'react';
import MobileDatePicker from '@material-ui/lab/MobileDatePicker';
import { Box, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const DatePickerComponent = ({ label, selectedDate, setSelectedDate, changeLabel }) => (
  <Box sx={{ mr: 2 }}>
    <MobileDatePicker
      inputFormat="d/M/yyyy"
      label={label}
      onChange={(newDate) => {
        setSelectedDate(newDate);
        changeLabel('Custom');
      }}
      renderInput={(inputProps) => (
        <TextField
          variant="outlined"
          {...inputProps}
        />
      )}
      value={selectedDate}
    />
  </Box>
);

DatePickerComponent.propTypes = {
  label: PropTypes.string.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  changeLabel: PropTypes.func.isRequired
};
export default DatePickerComponent;
