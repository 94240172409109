import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Container, Divider, Grid, Link, Typography } from '@material-ui/core';

import useSettings from '../../hooks/useSettings';
import MapMain from '../../components/map/MapMain';
import SelectableMarker from '../../components/map/SelectableMarker';
import OrderMapShowDataCard from '../../components/orders/OrderMapShowDataCard';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_ORDERS } from '../../queries/orders';
import { getDateRange } from '../../components/dashboardAnalytics/preDefinedDates';
import gtm from '../../lib/gtm';
import ChevronRightIcon from '../../icons/ChevronRight';
import { Link as RouterLink } from 'react-router-dom';
import DatePickerComponent from '../../components/formFields/DatePicker/DatePickerComponent';
import DatePickerDropDown from '../../components/dashboardAnalytics/DatePickerDropDown';
import moment from 'moment';

const OrdersMap = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedOption, setSelectedOption] = useState('TODAY');
  const [getOrders, { data: OrdersData, error, loading: OrdersLoading }] = useLazyQuery(GET_ORDERS);
  const orders = OrdersData?.adminGetOrders ?? [];
  console.log({ orders });
  useEffect(() => {
    if (selectedOption !== 'Custom') {
      setStartDate(getDateRange(selectedOption).start);
      setEndDate(getDateRange(selectedOption).end);
    }
  }, [selectedOption]);
  const [selectedMarkerId, setSelectedMarkerId] = useState();
  const [mapCenter, setMapCenter] = useState({ lat: 33.8871, lng: 35.4741 });
  const { settings } = useSettings();
  useEffect(() => {
    gtm.push({ event: 'page_view' });
    getOrders({
      pollInterval: 5000,
      variables: {
        startDate: moment(startDate).format('DD-MM-YYYY'),
        endDate: moment(endDate).format('DD-MM-YYYY')
      }
    });
  }, []);
  useEffect(() => {
    getOrders({
      pollInterval: 5000,
      variables: {
        startDate: moment(startDate).format('DD-MM-YYYY'),
        endDate: moment(endDate).format('DD-MM-YYYY')
      }
    });
  }, [startDate, endDate]);
  return (
    <>
      <Helmet>
        <title>Dashboard: Order Details Map</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Order List
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Orders
                </Typography>
              </Breadcrumbs>

            </Grid>
          </Grid>
          <Divider sx={{ mt: 2 }} />
          <Box
            sx={{
              display: 'flex',
              mt: 2
            }}
          >
            <DatePickerComponent
              selectedDate={startDate}
              label="Start Date"
              setSelectedDate={setStartDate}
              changeLabel={setSelectedOption}
            />
            <DatePickerComponent
              selectedDate={endDate}
              label="End Date"
              setSelectedDate={setEndDate}
              changeLabel={setSelectedOption}
            />
            <DatePickerDropDown
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xl={6}
                xs={12}
              >
                <MapMain
                  center={mapCenter}
                  zoom={11}
                >
                  {orders.map(({ acceptedOn, pickedUpOn, _id, address: { coordinates: { latitude: lat, longitude: lng } } }) => {
                    // eslint-disable-next-line no-nested-ternary
                    let url = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
                    if (acceptedOn) {
                      url = 'http://maps.google.com/mapfiles/ms/icons/blue-pushpin.png';
                    }
                    if (pickedUpOn) {
                      url = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
                    }
                    return (
                      <SelectableMarker
                        key={_id}
                        icon={{ url }}
                        id={_id}
                        lat={lat}
                        lng={lng}
                        selectedMarkerId={selectedMarkerId}
                        setSelectedMarkerId={setSelectedMarkerId}
                      />
                    );
                  })}
                </MapMain>
              </Grid>
              <Grid
                item
                md={6}
                xl={6}
                xs={12}
              >
                {selectedMarkerId ? (
                  <OrderMapShowDataCard orderId={selectedMarkerId} />
                ) : <h1>Select Marker to show data</h1>}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default OrdersMap;
