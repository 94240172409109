import { gql, useMutation } from '@apollo/client';

const CREATE_DRIVER = gql`
mutation CreateDriver($driver: DriverInput){
    createDriver(driver: $driver){
      _id
      firstName
      lastName
      isActivated
      zones
      mobile
      activationCode
    }
  }`;

const EDIT_DRIVER = gql`
mutation EditDriver($driverId: String, $driver: DriverInput){
  editDriver(driverId: $driverId,driver: $driver){
    firstName
    lastName
    isActivated
    isBlocked
    zones
    mobile
    activationCode
  }
}
`;
export const CHANGE_DRIVER_STATUS = gql`
mutation changeStatus($driverId:ID!,$active:Boolean!){
  changeDriverStatus(driverID:$driverId,active: $active ){
    firstName
    lastName
    isActivated
    isBlocked
    zones
    mobile
    activationCode
  }
}
`;

export { EDIT_DRIVER, CREATE_DRIVER };
