import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { Box, Drawer } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import StoreIcon from '@material-ui/icons/Store';
import ChartPieIcon from '../icons/ChartPie';
import SettingsIcon from '@material-ui/icons/Settings';
import FolderOpenIcon from '../icons/FolderOpen';
import RedeemIcon from '@material-ui/icons/Redeem';
import UsersIcon from '../icons/Users';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import RoomIcon from '@material-ui/icons/Room';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Logo from './Logo';
import NavSection from './NavSection';
import Scrollbar from './Scrollbar';
import MapIcon from '@material-ui/icons/Map';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import StorageIcon from '@material-ui/icons/Storage';

const sections = [

  {
    title: 'Management',
    items: [
      {
        title: 'Customers',
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/customers',
          },
          {
            title: 'Map',
            path: '/customers/map',
          },
        ]

      },
      {
        title: 'Drivers',
        path: '/drivers',
        icon: <DriveEtaIcon fontSize="small" />,

      },

      {
        title: 'Orders',
        icon: <FolderOpenIcon fontSize="small" />,
        path: '/orders',
        children: [
          {
            title: 'All Orders',
            path: '/orders'
          },
          {
            title: 'Map',
            path: '/orders/map'
          },
          {
            title: 'Top Orders',
            path: '/orders/top-orders'
          },
          {
            title: 'Cancelled Orders',
            path: '/orders/cancelledOrders'
          }
        ]
      },
      {
        title: 'Payments',
        path: '/payments',
        icon: <AttachMoneyIcon fontSize="small" />,

      },
      {
        title: 'Eco Scanner - BETA',
        path: '/eco-scanners',
        icon: <FiberNewIcon fontSize="small" />,

      },
      {
        title: 'Partners - BETA',
        path: '/partners',
        icon: <UsersIcon fontSize="small" />,

      },
      {
        title: 'Crowd Fund',
        path: '/crowd-funds',
        icon: <MonetizationOnIcon fontSize="small" />,

      },
      {
        title: 'Space',
        path: '/spaces',
        icon: <StoreIcon fontSize="small" />,

      },
      {
        title: 'Coupons - BETA',
        path: '/coupons',
        icon: <RedeemIcon fontSize="small" />
      },
      {
        title: 'Settings - BETA',
        icon: <SettingsIcon fontSize="small" />,
        children: [
          {
            title: 'Open Hours Edit',
            path: '/settings/open-hours'
          },
          {
            title: 'App Force Update',
            path: '/settings/app-force-update'
          },
          {
            title: 'Analytics Images',
            path: '/settings/analytics-images'
          }
        ]

      }
    ]
  },
  {
    title: 'Wiki',
    items: [
      {
        title: 'How To Recycle',
        path: '/recyclable-items',
        icon: <LiveHelpIcon fontSize="small" />
      }
    ]
  },
  {
    title: 'Statics',
    items: [
      {
        title: 'Statics',
        path: '/statics',
        icon: <StorageIcon fontSize="small" />
      }
    ]
  },
  {
    title: 'Analytics',
    items: [
      {
        title: 'Analytics - BETA',
        path: '/analytics',
        icon: <ChartPieIcon fontSize="small" />,
        children: [
          {
            title: 'New Users',
            path: '/analytics/new-users'
          },
          {
            title: 'Active Days',
            path: '/analytics/active-days'
          },
          {
            title: 'All Orders',
            path: '/analytics/orders-stats'
          },
          {
            title: 'Number Of Orders Per Zone',
            path: '/analytics/number-of-orders-by-zone'
          },
          {
            title: 'New Users Percent Change',
            path: '/analytics/new-users-percent-change'
          },
          {
            title: 'Orders Percent Change - BETA',
            path: '/analytics/orders-percent-change'
          },
          {
            title: 'Frequent User Driver Orders',
            path: '/analytics/orders-frequency'
          }
        ]
      },
    ]
  },
  {
    title: 'Maps',
    items: [
      {
        title: 'Zones - BETA',
        path: 'zones/',
        icon: <MapIcon fontSize="small" />
      },
      {
        title: 'Drop-Off - BETA',
        path: '/dropOff',
        icon: <RoomIcon fontSize="small" />,
        children: [
          {
            title: 'Map',
            path: '/dropOff/map'
          },
          {
            title: 'Create',
            path: '/dropOff/create'
          }
        ]
      },

      {
        title: 'Live Map - BETA',
        path: '/liveMap',
        icon: <MapIcon fontSize="small" />,
        children: [
          {
            title: 'View Drivers',
            path: '/liveMap/'
          }
        ]
      }
    ]
  },

];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>

      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
