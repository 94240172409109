import { gql } from '@apollo/client';

const GET_ORDERS = gql`
  query adminGetOrders($userId:ID,$driverId:ID,$status:OrderStatus,$offset:Int,$limit:Int,$startDate:String,$endDate:String){
    adminGetOrders(user:$userId,driver:$driverId,status:$status,offset:$offset,limit:$limit,startDate: $startDate,endDate: $endDate){
      _id
      numberOfBags
      requestedOn
      acceptedOn
      arrivedOn
      paidOn
      pickedUpOn
      deliveredOn
      driver{
        _id
        firstName
        lastName
      }
      zones{
        _id
        name
      }
      user{
        _id
        firstName
        lastName
      }
      payment{
        amount
      }
      rating{
        score
      }
      address{
        coordinates{
          latitude
          longitude
        }
      }
    }
  }
`;
const GET_TOP_ORDER_DATES = gql`
  query getTopOrderDates{
    getTopOrderDates{
      count
      date
    }
  }`;
const GET_ORDER_BY_ID = gql`
  query getOrderById($orderId:ID){
    order(orderId:$orderId){
      _id
      user{
        _id
        firstName
        lastName
        email
        mobile
      }
      address{
        name
        coordinates{
          latitude
          longitude
        }
      }
      driver{
        _id
        firstName
        lastName
        mobile
      }
      numberOfBags
      bags{
        name
        quantity
      }
      note
      acceptedOn
      arrivedOn
      paidOn
      pickedUpOn
      deliveredOn
      requestedOn
      payment{
        amount
        method
      }
      isCancelled
      zones{
        _id
        name
      }
      rating{
        score
        message
      }
      cancellation{
        cancelledOn
        reason
      }
    }
  }
`;
const GET_CANCELLED_ORDERS = gql`
  query getCanceledOrders{
    getCanceledOrders{
      _id
      numberOfBags
      requestedOn
      acceptedOn
      pickedUpOn
      driver{
        _id
        firstName
        lastName
      }
      zones{
        _id
        name
      }
      user{
        _id
        firstName
        lastName
      }
      payment{
        amount
      }

    }
  }
`;
export {
  GET_ORDERS,
  GET_ORDER_BY_ID,
  GET_CANCELLED_ORDERS,
  GET_TOP_ORDER_DATES
};
