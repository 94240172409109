import React, { useCallback, useMemo } from 'react';
import { Marker } from '@react-google-maps/api';

const markerDefaultIcon = { url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png' };
const markerSelectedIcon = { url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png' };
// eslint-disable-next-line react/prop-types
const SelectableMarker = ({ lat, lng, id, selectedMarkerId, setSelectedMarkerId, title, icon }) => {
  const isSelected = useMemo(() => id === selectedMarkerId, [id, selectedMarkerId]);
  const handleMarkerSelect = useCallback(() => {
    setSelectedMarkerId(id);
  }, [id, setSelectedMarkerId]);

  const markerIcon = useMemo(() => (id && isSelected ? markerSelectedIcon : markerDefaultIcon), [isSelected]);

  return (
    <Marker
      key={id}
      position={{ lat, lng }}
      title={title}
      onClick={id && handleMarkerSelect}
      icon={icon ?? (id && markerIcon)}
    />
  );
};

export default SelectableMarker;
