import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import firebase from 'firebase';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT
});

console.log('GRAPH', process.env.REACT_APP_GRAPHQL_ENDPOINT);

const authLink = setContext(async (_, { headers }) => {
  const token = await firebase.auth().currentUser.getIdToken();
  if (!token) {
    return {};
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      type: 'admin'
    }
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});
export default client;
