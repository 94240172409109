import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';

// eslint-disable-next-line react/prop-types
const CheckBoxComponent = ({ classes, data, handleChange, formLabel }) => (

  <FormControl
    component="fieldset"
       // eslint-disable-next-line react/prop-types
    className={classes.formControl ?? ''}
  >
    <FormLabel component="legend">{formLabel}</FormLabel>
    <FormGroup>
      {/* eslint-disable-next-line react/prop-types */}
      {data?.map(({ id, name }) => (
        <FormControlLabel
          key={id}
          control={(
            <Checkbox
              name="categories"
              value={name}
              onChange={handleChange}
            />
            )}
          label={name}
        />
      ))}
    </FormGroup>
  </FormControl>
);

export default CheckBoxComponent;
