import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, Card, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { CREATE_DRIVER } from '../../mutations/driver';
import { useMutation } from '@apollo/client';
import wait from '../../utils/wait';
import { toast } from 'react-hot-toast';
import FormControl from '@material-ui/core/FormControl';
import { vehicleTypes } from '../../constants/vehicleTypes';

const DriverCreateForm = () => {
  const [createDriver, { data, loading, error }] = useMutation(CREATE_DRIVER);
  console.log(`Error:::${error}`);
  return (
    <Formik
      onSubmit={async (driver) => {
        console.log({ driver });

        await createDriver({ variables: { driver } }).then(async () => {
          if (error) {
            console.log(`Error:::${error}`);
          } else {
            toast.success('Created New Driver!');
            await wait(700);
            window.location.replace('/drivers');
          }
        });
      }}
      initialValues={{
        firstName: '',
        lastName: '',
        mobile: '',
        // maxRequests: '',
        // maxBags: '',
        // vehicle: ''
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (

        <Card>
          <Box sx={{ p: 3 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xl={6}
                xs={12}
              >
                <TextField
                  autoComplete="off"
                  fullWidth
                  label="First name"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.firstName}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xl={6}
                xs={12}
              >
                <TextField
                  autoComplete="off"
                  fullWidth
                  label="Last name"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.lastName}
                  variant="outlined"
                />
              </Grid>

              <Grid
                item
                md={6}
                xl={6}
                xs={12}
              >
                <TextField
                  autoComplete="off"
                  error={Boolean(error)}
                  fullWidth
                  helperText={touched.mobile && errors.mobile && error}
                  label="Mobile number"
                  name="mobile"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.mobile}
                  variant="outlined"
                  required
                />
                {error ? (<div style={{ color: 'red' }}>{error.toString()}</div>) : null}
              </Grid>
              <Grid
                item
                md={6}
                xl={6}
                xs={12}
              >
                <TextField
                  autoComplete="off"
                  error={Boolean(error)}
                  fullWidth
                  type="number"
                  label="Max Number of Bags"
                  name="maxBags"
                 /* onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.maxBags}
                  required */
                  variant="outlined"

                />
              </Grid>
              <Grid
                item
                md={6}
                xl={6}
                xs={12}
              >
                <TextField
                  autoComplete="off"
                  error={Boolean(error)}
                  fullWidth
                  type="number"
                  label="Max Number of Requests"
                  name="maxRequests"
                  /* onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.maxRequests}
                  required */
                  variant="outlined"
                />
              </Grid>
              {/* <Grid
                item
                md={6}
                xl={6}
                xs={12}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Vehicle Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values.vehicle}
                    label="Vehicle Type"
                    onChange={handleChange}
                    name="vehicle"
                  >
                    {vehicleTypes.map((x) => (
                      <MenuItem value={x}>{x}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
            </Grid>
            <Box sx={{ mt: 2 }}>
              <Button
                onClick={handleSubmit}
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Create Driver
              </Button>
            </Box>
          </Box>
        </Card>

      )}
    </Formik>
  );
};

export default DriverCreateForm;
