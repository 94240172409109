import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  Grid,
  TextField
} from '@material-ui/core';
import HourComponent from '../formFields/HourComponent';

const defaultValues = {
  name: '',
  address: '',
  startTime: '07:30',
  endTime: '19:30'
};

// eslint-disable-next-line react/prop-types
const CreateZoneForm = ({ initialValues = defaultValues, path }) => (
  <div>
    <Formik
      onSubmit={(data) => {
        data.path = path;
        console.log(data);
        return data;
      }}
      initialValues={initialValues}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => ((

        <Card>
          <Box sx={{ p: 3 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item />
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Address"
                  name="address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.address}
                  variant="outlined"
                />
              </Grid>
              <Grid item />
              <HourComponent
                handleBlur={handleBlur}
                handleChange={handleChange}
                values={values.startTime}
                name="startTime"
              />
              <HourComponent
                handleBlur={handleBlur}
                handleChange={handleChange}
                values={values.endTime}
                name="endTime"
              />

            </Grid>
            <Box sx={{ mt: 2 }}>
              <Button
                onClick={handleSubmit}
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Create Zone
              </Button>
            </Box>
          </Box>
        </Card>
      ))}
    </Formik>
  </div>
);

export default CreateZoneForm;
